import "../Footer/footer.css";

const Footer = () => {
  return (
    <div className="footerclass">
      <div className="endereco">
        Endereço: Rua Visconde do Rio Branco, 732, Vila Jardini
      </div>
      <div className="contact1">
        <div className="contato1">Telefone: (15) 3321-3564</div>
      </div>
      <div className="dev">
        Desenvolvido por:{" "}
        <span>
          <a
            href="https://www.instagram.com/yasmingdev/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Yasmin Gonçalves.
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
