import React from "react";
import "./Inicial.css";
import Fundo from "../../images/background.png";
import Mulher from "../../images/mulher.png";
import whatsapp from "../../images/whatsapp.svg";
import instagram from "../../images/instagram.svg";

const Inicial = () => {
  return (
    <div className="body">
      <img src={Mulher} alt="mulher" />
      <div className="sobre">
        <h1>Sobre Nós</h1>
        <h2>
          Apresentamos a Império Ferramentas Materiais para Construção, uma loja
          em Sorocaba que desde 2021 oferece uma vasta gama de produtos para
          construção, reforma e manutenção. Localizada na Rua Visconde do Rio
          Branco 732 Vila Jardini , nossa loja proporciona uma experiência
          excepcional, com uma equipe especializada pronta para oferecer
          assistência personalizada, uma extensa seleção de produtos, incluindo
          materiais hidráulicos, elétricos, ferramentas de qualidade e tintas de
          diversas variedades. Trabalhamos apenas com marcas confiáveis,
          garantindo a durabilidade e o desempenho de nossos produtos. Além
          disso, nossos preços são imbatíveis, assegurando que nossos clientes
          obtenham o melhor valor em cada compra. Na Império Materiais para
          Construção, estamos comprometidos em tornar a jornada de construção
          dos nossos clientes mais fácil, conveniente e acessível.
        </h2>
      </div>
      <div className="contato">
        <h1>Contato</h1>
        <h2>
          Entre em contato para <br />
          solicitar um orçamento
        </h2>
        <div className="whats">
          <img src={whatsapp} alt="whatsapplogo" />
          <p>
            <a href="https://api.whatsapp.com/send?phone=55015997601288">
              Whatsapp
            </a>
          </p>
        </div>
        <div className="instagram">
          <img src={instagram} alt="instagramlogo" />
          <p>
            {" "}
            <a href="https://www.instagram.com/imperioferramentas_/">
              Instagram
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Inicial;
