import React from "react";
import "./NavBar.css";
import topo from "../../images/header.png";

const NavBar = () => {
  return (
    <div className="navbar">
      <img src={topo} alt="header" />
    </div>
  );
};

export default NavBar;
